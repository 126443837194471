import * as s from './styles/downloads.module.scss';
import CardDownload from '../components/card_download';
import Layout from '../components/layout';
import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

const query = graphql`
{
  allFile(filter: {sourceInstanceName: {eq: "downloads"}}) {
    nodes {
      name
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
      absolutePath
      extension
      fields { 
        content
      }
    }
  }
}
`

interface Data {
  allFile: {
    nodes: [{
      name: string
      publicURL: string
      childImageSharp: any
      absolutePath: string
      extension: string
      fields: { content: string }
    }]
  }
}

interface Download {
  publicUrl?: string
  gatsbyImage?: IGatsbyImageData
}

export default function Downloads() {
  const { allFile: { nodes: data } }: Data = useStaticQuery(query)

  const downloadList = new Map<string, Download>()

  data.forEach(element => {
    if (!downloadList.has(element.name))
      downloadList.set(element.name, {})
    if (element.childImageSharp)
      downloadList.get(element.name)!.gatsbyImage = element.childImageSharp.gatsbyImageData
    else if (element.extension === 'txt') {
      downloadList.get(element.name)!.publicUrl = element.fields.content
    } else
      downloadList.get(element.name)!.publicUrl = element.publicURL
  })

  const downloadsHtml: [ReactNode?] = []

  for (let [name, download] of downloadList) {
    downloadsHtml.push((
      <CardDownload
        href={download.publicUrl ?? ''}
        img={download.gatsbyImage!}
        title={name}
        key={name} />))
  }

  return (
    <Layout title="Downloads" gray>
      <main>
        <section className={s.Downloads}>
          <h1 className="title u-margin-bottom">Downloads</h1>
          <div className={s.Downloads_box}>
            {downloadsHtml}
          </div>
        </section>
      </main>
    </Layout>
  )
}
