import * as s from './styles/card_download.module.scss';
import React, { ReactElement } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  title: string,
  img: IGatsbyImageData,
  href: string,
}

export default function CardDownload({ title, img, href }: Props): ReactElement {
  return (
    <a href={href} target="_blank" className={s.Card}>
      <GatsbyImage image={img} alt={title} className={s.Card_image} />
      <h2 className="topic">{title}</h2>
    </a>
  )
}
